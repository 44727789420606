<template>
  <div class="card-slider">
    <div class="buttons" @click="slideLeft" style="cursor: pointer;">
      <!-- <v-btn @click="slideLeft" color="transparent" elevation="0" flat> -->
        <v-icon  elevation="0" flat x-large>mdi-chevron-left</v-icon>
      <!-- </v-btn> -->
    </div>
    <transition-group name="card" tag="div" class="card-container">
      <Card
        v-for="(card, index) in cards"
        :key="card.id"
        :title="card.title"
        :year="card.year"
        :link="card.link"
        :style="`${index === 1 ? `z-index: 1; height:400px;` : (index === 2 ? `z-index: -1; height:300px; left:-40%` : `z-index: -1; height:300px; right:-40%`)}`"
        style="position: relative;"
      />
    </transition-group>

    <!-- <div class="buttons">
      <v-btn @click="slideRight" color="transparent" elevation="0" flat >
        <v-icon x-large>mdi-chevron-right</v-icon>
      </v-btn>
    </div> -->

    <div class="buttons" @click="slideRight" style="cursor: pointer;">
      <!-- <v-btn @click="slideRight" color="transparent" elevation="0" flat> -->
        <v-icon  elevation="0" flat x-large>mdi-chevron-right</v-icon>
      <!-- </v-btn> -->
    </div>
  </div>
</template>

<script>
import Card from './card.vue'; // Pastikan path-nya sesuai dengan struktur proyek Anda

export default {
  components: {
    Card,
  },
  data() {
    return {
      cards: [
        {
            id: 2,
            year: '2019',
            title: 'The first to implement Confluent Enterprise at biggest consumer bank in Indonesia',
            link: 'https://www.confluent.io/customers/bri/',
        },
        {
            id: 1,
            year: '2021',
            title: 'I-Motion integrates data from Vessel Traffic Services (VTS) and Coastal Radio Stations (SROP) as well as various other sources, in order to carry out the monitoring of vessel traffic in Indonesian maritime',
            link: 'https://www.antaranews.com/berita/2558921/kemenhub-luncurkan-sistem-integrasi-kenavigasian-i-motion',
        },
        {
            id: 3,
            year: '2020',
            title: 'Qlik Announces Recipients of its Global and Regional Partner Awards Awards Recognize Qlik`s Partner Community for Excellence in Achieving Joint Customer Success Through Innovation',
            link: 'https://www.qlik.com/blog/qlik-announces-recipients-of-its-global-and-regional-partner-awards',
        },
        // Tambahkan data kartu selanjutnya
      ],
    };
  },
  methods: {
    slideLeft() {
      // Metode untuk menggeser kartu ke kiri (Previous)
      const lastCard = this.cards.pop();
      this.cards.unshift(lastCard);
    },
    slideRight() {
      // Metode untuk menggeser kartu ke kanan (Next)
      const firstCard = this.cards.shift();
      this.cards.push(firstCard);
    },
  },
};
</script>

<style>
/* Gaya container kartu */
.card-slider {
    grid-row: 1 / 2;
    grid-column: 1 / 8;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.card-container {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr); /* Atur jumlah kolom kartu sesuai kebutuhan */
  grid-gap: 10px; /* Jarak antar kartu */
  overflow: hidden;
}

.buttons {
  margin-top: 20px;
  text-align: center;
}

.buttons button {
  margin: 5px;
  padding: 10px 20px;
  /* background-color: #007bff; */
  /* color: #fff; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.buttons button:hover {
  /* background-color: #0056b3; */
  font-weight: 400;
}
</style>
