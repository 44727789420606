<template>
  <!-- Start Service Area  -->
  <v-row class="service-one-wrapper">
    <!-- Start Single Service  -->
    <v-col
      lg="6"
      md="6"
      sm="6"
      cols="12"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="single-service service__style--siber" style="height: 300px !important;">
        <!-- <router-link to="/service-details"> -->
          <div class="service">
            <div class="icon" v-html="iconSvg(service.icon)"></div>
            <div class="content">
              <h3 class="heading-title" style="font-size: 24px !important;">{{ service.title }}</h3>
              <p style="font-size: 18px !important;">
                {{ service.desc }}
              </p>
            </div>
          </div>
        <!-- </router-link> -->
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
  <!-- Start Service Area  -->
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        serviceContent: [
          {
            icon: "briefcase",
            title: "Experience",
            desc:  `Multi Years Of Experience In Implementing of Data Streaming And Integration Projects.`,
          },
          {
            icon: "command",
            title: "Collaboration",
            desc: `Business-Partner Collaboration With Multidisciplinary Specialist To Strengthen Our Offerings.`,
          },
          {
            icon: "thumbs-up",
            title: "Expertise",
            desc:  `Our Teams Consist Of Passionate And Dynamic Professionals With Regular Refreshed Necessary Knowledge And Skill Set.`,
          },
          {
            icon: "star",
            title: "Top-notch Solution",
            desc: `Preferred Local Partner Of Top Product Principles In Real-Time Data Streaming Ecosystem, Trusted By Half Of The FORTUNE 100. Together We Tailor The Right Solution For You.`,
          },
        ],
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
