import { render, staticRenderFns } from "./AboutSiber.vue?vue&type=template&id=26cf36d4&scoped=true&"
import script from "./AboutSiber.vue?vue&type=script&lang=js&"
export * from "./AboutSiber.vue?vue&type=script&lang=js&"
import style0 from "./AboutSiber.vue?vue&type=style&index=0&id=26cf36d4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26cf36d4",
  null
  
)

export default component.exports