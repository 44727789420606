<template>
    <v-row>
      <!-- Start Single Counterup  -->
      <v-col
        lg="3"
        md="3"
        sm="3"
        cols="12"
      >
        <div class="rn-counterup flex-column counterup_style--1" >
          <p class="counter count ">
            Built for Historical Data
          </p>
          <div class="d-flex justify-center">
            <img src="../../assets/images/icons/historical.png" class="icon-recolor" />
          </div>
          <p class="counter count ">
            Built for Real-Time Events
          </p>
          <p class="description ">
            <b>Value: </b>Trigger real-time workflows (i.e. real-time order management)
          </p>
        </div>
      </v-col>

      <v-col
        lg="3"
        md="3"
        sm="3"
        cols="12"
      >
        <div class="rn-counterup flex-column counterup_style--1">
          <p class="counter count ">
            Scalable for Transactional Data
          </p>
          <div class="d-flex justify-center">
            <img src="../../assets/images/icons/transaction.png" class="icon-recolor"/>
          </div>
          <p class="counter count ">
            Scalable for All Data
          </p>
          <p class="description ">
            <b>Value: </b>Scale across the enterprise (i.e. customer 360)
          </p>
        </div>
      </v-col>

      <v-col
        lg="3"
        md="3"
        sm="3"
        cols="12"
      >
        <div class="rn-counterup flex-column counterup_style--1">
          <p class="counter count ">
            Transient
          </p>
          <div class="d-flex justify-center">
            <img src="../../assets/images/icons/transient.png" class="icon-recolor"/>
          </div>
          <p class="counter count ">
            Presistent + Durable
          </p>
          <p class="description ">
            <b>Value: </b>Build mission-critical apps with zero data loss (i.e. instant payment)
          </p>
        </div>
      </v-col>

      <v-col
        lg="3"
        md="3"
        sm="3"
        cols="12"
      >
        <div class="rn-counterup flex-column counterup_style--1">
          <p class="counter count ">
            Raw Data
          </p>
          <div class="d-flex justify-center">
            <img  src="../../assets/images/icons/raw-data.png" class="icon-recolor"/>
          </div>
          <p class="counter count ">
            Enriched Data
          </p>
          <p class="description">
            <b>Value: </b>Add context & situational awareness (i.e. ride sharing ETA)
          </p>
        </div>
      </v-col>
      <!-- Start Single Counterup  -->
    </v-row>
  </template>
  
  <script>
    import VisibilitySensor from "vue-visibility-sensor";
    import countTo from "vue-count-to";
  
    export default {
      components: {
        countTo,
        VisibilitySensor,
      },
      data() {
        return {
          countUp: true,
          status: false,
          counterUpContent: [
            {
              endVal: 992,
              desc: `The standard chunk of Lorem Ipsum used since the 1500s is reproduced
              below for those.`,
            },
            {
              endVal: 575,
              desc: `The standard chunk of Lorem Ipsum used since the 1500s is reproduced
              below for those.`,
            },
            {
              endVal: 69,
              desc: `The standard chunk of Lorem Ipsum used since the 1500s is reproduced
              below for those.`,
            },
          ],
        };
      },
      methods: {
        onChange(isVisible) {
          if (isVisible) {
            this.status = true;
          }
        },
      },
    };
  </script>
  <style>
    .icon-recolor {
      filter: invert(85%);
    }
  </style>
  