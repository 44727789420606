<template>
  <div class="row--20">
    <div class="about-inner inner">
      <v-row style="margin-bottom: 10vh">
        <v-col lg="5">
          <v-img src="../../assets/images/bg/gis.webp" height="370" width="600"/>
        </v-col>
        <v-col lg="7">
          <div class="pt-5">
            <div class="section-title mb--20">
              <h4 class="text-h4 font-weight-regular siber-title">Sibernetik Owned Propriatery Solution</h4>
              <hr color="#dd8732" width="650px;">
              <h5 class="heading-title mb-5 text-h5 font-weight-regular SiberSub-title">
                SaaS solution and deliver compeling data streaming & cyber security solutions to enterprises
              </h5>
            </div>
            <div class="text-left">
              <table>
                <tr>
                  <td class="centang"><v-img :src="checkList"/></td>&nbsp;
                  <td>
                    <span class="text-body-1">Raltime inquiry engine. Rapidly transform digital services to meet customers’ rising expectations for highly secure, personalized and valuable experiences.</span>
                  </td>
                </tr>
                <tr>
                  <td class="centang"><v-img :src="checkList"/></td>&nbsp;
                  <td>
                    <span class="text-body-1">Near-space (Enterprise) - Situational Awareness.</span>
                  </td>
                </tr>
                <tr>
                  <td class="centang"><v-img :src="checkList"/></td>&nbsp;
                  <td>
                    <span class="text-body-1">Realtime Geospatial Analytics.</span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="5">
          <v-img src="../../assets/images/bg/notif.webp" height="370" width="600"/>
        </v-col>
        <v-col lg="7">
          <div class="pt-5">
            <div class="section-title mb--20">
              <h4 class="text-h4 font-weight-regular siber-title">Consulting & System Integration Solutions</h4>
              <hr color="#dd8732" width="720px;">
              <h5 class="heading-title mb-5 text-h5 font-weight-regular SiberSub-title">
                To enable the existing enterprise monolithic legacy system into event driven microservices (OLTP: AS400, Mainframe, SAP, Oracle, Ms SQL, DB2, etc). To build active intelligence capability to transform the existing data insight into action
              </h5>
            </div>
            <div class="text-left">
              <table>
                <tr>
                  <td class="centang"><v-img :src="checkList"/></td>&nbsp;
                  <td>
                    <span class="text-body-1">Qlik Active Intelligence. Embedded data & analytics services to deliver actionable insight</span>
                  </td>
                </tr>
                <tr>
                  <td class="centang"><v-img :src="checkList"/></td>&nbsp;
                  <td>
                    <span class="text-body-1">Native Real time Streaming. Combining Qlik Replicate, Confluent Platform and Imply to deliver native reatlime analytics solutions.</span>
                  </td>
                </tr>
                <tr>
                  <td class="centang"><v-img :src="checkList"/></td>&nbsp;
                  <td>
                    <span class="text-body-1">Stream Processing. Combining Qlik Replicate, Confluent Platform and optimizing Apache Flink and stream processing to deliver realtime transformation to support realtime reporting & notification</span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- <div class="about-inner inner">
      <div class="section-title d-flex flex-column mb--20 ">
        <h4 class="text-h4 font-weight-regular siber-title">Consulting & System Integration Solutions</h4>
        <hr color="#dd8732" width="720px;">
        <h5 class="heading-title mb-5 text-h5 font-weight-regular SiberSub-title">
          To enable the existing enterprise monolithic legacy system into event driven microservices (OLTP: AS400, Mainframe, SAP, Oracle, Ms SQL, DB2, etc). To build active intelligence capability to transform the existing data insight into action
        </h5>
      </div>
      <div class="text-left">
        <table>
          <tr>
            <td class="centang"><v-img :src="checkList"/></td>&nbsp;
            <td>
              <span class="text-body-1">Qlik Active Intelligence. Embedded data & analytics services to deliver actionable insight</span>
            </td>
          </tr>
          <tr>
            <td class="centang"><v-img :src="checkList"/></td>&nbsp;
            <td>
              <span class="text-body-1">Native Real time Streaming. Combining Qlik Replicate, Confluent Platform and Imply to deliver native reatlime analytics solutions.</span>
            </td>
          </tr>
          <tr>
            <td class="centang"><v-img :src="checkList"/></td>&nbsp;
            <td>
              <span class="text-body-1">Stream Processing. Combining Qlik Replicate, Confluent Platform and optimizing Apache Flink and stream processing to deliver realtime transformation to support realtime reporting & notification</span>
            </td>
          </tr>
        </table>
      </div>
    </div> -->
  </div>
</template>
<script>
  import Accordion from "../../components/accordion/Accordion";
  import checkList from "../../assets/images/icons/checkList/check_23.png"
  export default {
    components: {
      Accordion,
    },
    data() {
      return {
        checkList:checkList,
      };
    },
  };
</script>
<style scoped>
.heading-expantion {
  line-height: 20px;
}
.centang {
  vertical-align: top;
  min-width: 17px;
  padding-top: 5px;
 }
.siber-title {
  font-family: 'Urbanist', sans-serif !important;
}
.SiberSub-title {
  font-family: 'Urbanist', sans-serif !important;
}
</style>