<template>
<div>
  <v-row class="row--35" style="margin-bottom: 150px">
    <v-col md="5" cols="12">
      <div class="thumbnail">
        <img
          class="w-100"
          src="../../assets/images/about/about-10.jpg"
          alt="About Images"
        />
      </div>
    </v-col>
    <v-col md="7" cols="12" v-for="item in experience1" :key="item.id">
      <div class="about-inner inner">
        <div class="we-transform-customer-experience">
          <div class="title-experience d-flex flex-wrap">
            <h4 class="text-justify">
              {{ item.title }}
            </h4>
          </div>
          <h5>
            {{ item.desc }}
          </h5>
          <div class="list-experience">
            <ul class="list-style--1">
              <li class="d-flex align-start" v-for="(list, i) in item.content" :key="i">
                <i v-html="iconSvg(list.icon)"></i>
                {{ list.desc }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>

  <v-row class="row--35">
    <v-col md="7" cols="12" v-for="item in experience2" :key="item.id">
      <div class="about-inner inner">
        <div class="we-transform-customer-experience">
          <div class="title-experience d-flex flex-wrap" style="width: 450px;">
            <h4 class="text-justify">
              {{ item.title }}
            </h4>
          </div>
          <h5>
            {{ item.desc }}
          </h5>
          <div class="list-experience">
            <ul class="list-style--1">
              <li class="d-flex align-start" v-for="(list, i) in item.content" :key="i">
                <i v-html="iconSvg(list.icon)"></i>
                {{ list.desc }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </v-col>
    <v-col md="5" cols="12">
      <div class="thumbnail">
        <img
          class="w-100"
          src="../../assets/images/about/about-11.jpg"
          alt="About Images"
        />
      </div>
    </v-col>
  </v-row>
</div>
</template>
<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        tab: null,
        experience2: [
          {
            title: `Consulting & System Integration Solutions`,
            desc: ` To enable the existing enterprise monolithic legacy system into event driven microservices (OLTP: AS400, Mainframe, SAP, Oracle, Ms SQL, DB2, etc). To build active intelligence capability to transform the existing data insight into action`,
            content: [
              {
                icon: "check",
                desc: `Qlik Active Intelligence. Embedded data & analytics services to deliver actionable insight.`,
              },
              {
                icon: "check",
                desc: ` Native Real time Streaming. Combining Qlik Replicate, Confluent Platform and Imply to deliver native reatlime analytics solutions.`,
              },
              {
                icon: "check",
                desc: `Stream Processing. Combining Qlik Replicate, Confluent Platform and optimizing Apache Flink and stream processing to deliver realtime transformation to support realtime reporting & notification.`,
              },
            ],
          },
        ],
        experience1: [
          {
            title: `Sibernetik Owned Propriatery Solution`,
            desc: `SaaS solution and deliver compeling data streaming & cyber security solutions to enterprises`,
            content: [
              {
                icon: "check",
                desc: `Raltime inquiry engine. Rapidly transform digital services to meet customers’ rising expectations for highly secure, personalized and valuable experiences.`,
              },
              {
                icon: "check",
                desc: `Near-space (Enterprise) - Situational Awareness.`,
              },
              {
                icon: "check",
                desc: `Realtime Geospatial Analytics.`,
              },
            ],
          },
        ]
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
