<template>
  <v-row class="mt-3">
    <v-col
      lg="4"
      md="4"
      sm="12"
      cols="12"
      v-for="pricing in pricingContent"
      :key="pricing.id"
    >
      <div class="rn-pricingsiber d-flex justify-center pt-3 my-3" :class="{ active: pricing.active }">
        <div class="pricing-table-inner">
          <div class="pricing-header">
            <h4 class="heading-title">{{ pricing.title }}</h4>
          </div>

          <div class="pricing-header">
            <div class="pricing">
              <span class="subtitle">{{ pricing.subtitle }}</span>
            </div>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    data() {
      return {
        pricingContent: [
          {
            id: 1,
            title: "Data Streaming",
            price: 29,
            subtitle: "This environment fills with the Data Streaming tools that processes data flow in a near realtime manner from the source to the target, in combination with change data capture tools",
            active: false,
          },
          {
            id: 2,
            title: "Application Development",
            price: 30,
            subtitle: "The Dev team with strong understanding of how to build you Application need that suites with you business cycle along with our incredible Core Processing Engine In-Platform will help you to boost you achievement on the records.",
            active: false,
          },
          {
            id: 3,
            title: "Security Sensor & Centralized Log Management",
            price: 29,
            subtitle: "This system equipped with our complex engineering schema for the precise detection system with the hassle that you don’t need to worry about.",
            active: false,
          },
        ],
      };
    },
  };
</script>
