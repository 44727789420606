<template>
  <v-row>
    <v-col cols="12">
      <p class="keterangan text-center" style="">
        Established since 2017, Sibernetik is an Indonesia Data Streaming Analytics & Security Firm. Lean team consist of individuals mastering state-of-the-art cross-industry enterprise tech: Realtime Data Replication, Realtime Data Streaming, Realtime Database, Microservices and Cyber Security.
      </p>

      <p class="keterangan text-center">
        Every day, we help clients engage with new technology paradigms, creatively building solutions that solve their most pressing business challenges and move them to the forefront of their industry.
      </p>
    </v-col>
    <!-- Start Single Counterup  -->
    <v-col
      lg="3"
      md="3"
      sm="3"
      cols="12"
    >
      <div class="rn-counterup flex-column counterup_style--1" >
        <div class="d-flex justify-center">
          <img class="enterprise-img" src="../../assets/images/icons/startup2.png"/>
        </div>
        <h3 class="counter count">
          Experience
        </h3>
        <p class="keterangan2">
          Multi Years of Experience in implementing data streaming and integration projects
        </p>
      </div>
    </v-col>

    <v-col
      lg="3"
      md="3"
      sm="3"
      cols="12"
    >
      <div class="rn-counterup flex-column counterup_style--1">
        <div class="d-flex justify-center">
          <img class="enterprise-img" src="../../assets/images/icons/user-experience2.png"/>
        </div>
        <h3 class="counter count ">
          Expertise
        </h3>
        <p class="keterangan2">
          Our teams consist of passionate and dynamic professionals with regular refreshed necessary knowledge and skill set
        </p>
      </div>
    </v-col>

    <v-col
      lg="3"
      md="3"
      sm="3"
      cols="12"
    >
      <div class="rn-counterup flex-column counterup_style--1">
        <div class="d-flex justify-center">
          <img class="enterprise-img" src="../../assets/images/icons/solution2.png" />
        </div>
        <h3 class="counter count ">
          Top-notch Solution
        </h3>
        <p class="keterangan2">
          Preferred Local Partner of top product principles in real-time data streaming ecosystem, trusted by half of the FORTUNE 100. Together we tailor the right solution for you
        </p>
      </div>
    </v-col>

    <v-col
      lg="3"
      md="3"
      sm="3"
      cols="12"
    >
      <div class="rn-counterup flex-column counterup_style--1">
        <div class="d-flex justify-center enterprise-img">
          <img class="enterprise-img" src="../../assets/images/icons/network2.png"/>
        </div>
        <h3 class="counter count ">
          Collaboration
        </h3>
        <p class="keterangan2">
          Business-partner Collaboration with multidisciplinary specialist to strengthen our offerings.
        </p>
      </div>
    </v-col>
    <!-- Start Single Counterup  -->
  </v-row>
</template>

<script>
  import VisibilitySensor from "vue-visibility-sensor";
  import countTo from "vue-count-to";

  export default {
    components: {
      countTo,
      VisibilitySensor,
    },
    data() {
      return {
        countUp: true,
        status: false,
        counterUpContent: [
          {
            endVal: 992,
            desc: `The standard chunk of Lorem Ipsum used since the 1500s is reproduced
            below for those.`,
          },
          {
            endVal: 575,
            desc: `The standard chunk of Lorem Ipsum used since the 1500s is reproduced
            below for those.`,
          },
          {
            endVal: 69,
            desc: `The standard chunk of Lorem Ipsum used since the 1500s is reproduced
            below for those.`,
          },
        ],
      };
    },
    methods: {
      onChange(isVisible) {
        if (isVisible) {
          this.status = true;
        }
      },
    },
  };
</script>
<style>
  .enterprise-img {
    max-height: 120px;
    padding-bottom: 10px;
  }
  .keterangan {
    
  }
  p.keterangan2 {
    font-size: 16px;
    color: #717173;
    line-height: 1.2 !important;
  }
  .enterprise-color {
      color: white !important;
  }
</style>
