<template>
  <v-row class="d-flex justify-center">
    <!-- Start Blog Area  -->
    <!-- <v-col cols="12" class="mb-10">
      <p style="text-align: center; padding: 0px; margin-top: 0px;">Sibernetik strives to design and deliver the right real-time data streaming solution to your company.</p>
      <p style="text-align: center; padding: 0px; margin-top: 0px;"><b>Preffered Local Partner</b> of best product principles in real-time data streaming ecosystem,<br/> trusted by half of the FORTUNE 100</p>
    </v-col> -->

    <v-col
        cols="12"
        xl="5"
        lg="5"
        md="5"
        sm="12"
        xs="12"
    >
      <div class="blog blog-style--extens-sol heading-line1">    
        <div class="content">
          <div class="mt-10 mb-8 text-center">
            <h3 class="blogsiber-title text-subtitle">Sibernetik Owned Propriatery Solution</h3>
            <span class="blogsiberSub-title text-subtitle-1">SaaS solution and deliver compeling data<br>streaming & cyber security solutions to enterprises</span>
          </div>
          <v-divider></v-divider>
          <table>
            <tr>
              <td class="centang"><v-img :src="checkList"/></td>&nbsp;
              <td>
                <span class="text-left text-body2 blogsiber-content">Realtime inquiry engine<br>
                  Rapidly transform digital services to meet customers’ rising expectations for highly secure, personalized and valuable experiences.
                </span>
              </td>
            </tr>
            <tr>
              <td class="centang"><v-img :src="checkList"/></td>&nbsp;
              <td>
                <span class="text-left text-body2 blogsiber-content">
                  Near-space (Enterprise) - Situational Awareness.
                </span>
              </td>
            </tr>
            <tr>
              <td class="centang"><v-img :src="checkList"/></td>&nbsp;
              <td>
                <span class="text-left text-body2 blogsiber-content">
                  Realtime Geospatial Analytics.
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </v-col>

    <v-col
        cols="12"
        xl="5"
        lg="5"
        md="5"
        sm="12"
        xs="12"
    >
      <div class="blog blog-style--extens-sol heading-line2">    
        <div class="content">
          <div class="mt-10 mb-8 text-center">
            <h3 class="blogsiber-title text-subtitle">Consulting & System Integration Solutions</h3>
            <span class="blogsiberSub-title text-subtitle-1">To enable the existing enterprise monolithic legacy system into event driven microservices (OLTP: AS400, Mainframe, SAP, Oracle, Ms SQL, DB2, etc). To build active intelligence capability to transform the existing data insight into action</span>
          </div>
          <v-divider></v-divider>
          <table>
            <tr>
              <td class="centang"><v-img :src="checkList2"/></td>&nbsp;
              <td>
                <span class="text-left text-body2 blogsiber-content">Qlik Active Intelligence<br>
                  Embedded data & analytics services to deliver actionable insight
                </span>
              </td>
            </tr>
            <tr>
              <td class="centang"><v-img :src="checkList2"/></td>&nbsp;
              <td>
                <span class="text-left text-body2 blogsiber-content">
                  Native Real time Streaming<br>
                  Combining Qlik Replicate, Confluent Platform and Imply to deliver native reatlime analytics solutions.
                </span>
              </td>
            </tr>
            <tr>
              <td class="centang"><v-img :src="checkList2"/></td>&nbsp;
              <td>
                <span class="text-left text-body2 blogsiber-content">
                  Stream Processing<br>
                  Combining Qlik Replicate, Confluent Platform and optimizing Apache Flink and stream processing to deliver realtime transformation to support realtime reporting & notification
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </v-col>
    
    <!-- End Blog Area  -->
  </v-row>
</template>

<script>
  import checkList from "../../assets/images/icons/checkList/check_26.png";
  import checkList2 from "../../assets/images/icons/checkList/check_20.png";

  export default {
    components: {
    },
      data() {
        return {
          checkList2:checkList2,
          checkList:checkList,
          selectedService: [
            {
              id: "confluent",
              dark: false,
            },
            {
              id: "attunity",
              dark: false,
            },
          ],
          selectedServiceIndex: "",
          
        };
      },
    };
</script>
<style scoped>
  .blogsiberSub-title {
    font-weight: 400;
    font-family: 'Urbanist', sans-serif !important;
  }
  .blogsiber-title {
    font-weight: 500;
    font-family: 'Urbanist', sans-serif !important;
  }
  .blogsiber-content {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    font-family: 'Urbanist', sans-serif !important;
  }
 .centang {
  vertical-align: top;
  min-width: 17px;
  padding-top: 5px;
 }
 .heading-line1 {
    border-top: 10px solid #00827B;
 }
 .heading-line2 {
    border-top: 10px solid #444965;
 }
</style>
