<template>
  <v-row style="padding-top: 100px">
    <!-- Start Single Team Area  -->
    <v-col
      lg="3"
      md="3"
      sm="12"
      xs="12"
      cols="12"
      class="d-flex justify-center"
      style="margin: auto;"
      v-for="team in teamContent"
      :key="team.id"
    >
      <!-- <div class="team">
        <div class="thumbnail">
          <img :src="team.src" alt="Team Images" />
        </div>
        <div class="content">
          <h4 class="title">{{ team.title }}</h4>
          <p class="designation">{{ team.designation }}</p>
        </div>
        <ul class="social-icon">
          <li v-for="(social, i) in team.socialList" :key="i">
            <a :href="social.url" target="_blank"
              ><i class="fab" :class="social.icon"></i
            ></a>
          </li>
        </ul>
      </div> -->

      <!-- <v-card
        class="mx-auto my-12 kartu-nama"
        width="374"
        height="500"
      >
        <div class="mx-auto text-center foto-kartu-nama">
          <v-avatar
            size="220"
          >
            <img :src="team.src" alt="Team Images"/>
          </v-avatar>
        </div>
        <div class="foto-kartu-nama2">
          <v-card-title>{{ team.title }}</v-card-title>
          <v-card-text>
            <div class="my-4 text-subtitle-1">
              {{ team.designation }}
            </div>
            <div>{{ team.description }}</div>
          </v-card-text>
        </div>
      </v-card> -->

      <div class="rn-team-area profile-card js-profile-card">
        <div class="profile-card__img">
          <img :src="team.src" style="object-fit: contain; background-color: #000000;"  alt="profile card"/>
        </div>
        <div class="profile-card__cnt js-profile-cnt">
          <div class="profile-card__name">{{ team.title }}</div>
          <div class="profile-card__title">
            <span>{{ team.designation }}</span>
          </div>
          <div class="profile-card__txt px-2">
            <span>{{ team.description }}</span>
          </div>
        </div>
      </div>
    </v-col>
    <!-- End Single Team Area  -->
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      teamContent: [
        {
          id: 1,
          src: require("../../assets/images/blog/Pak Stephen.png"),
          title: "STEPHEN Y.S.",
          designation: "BUSINESS DEVELOPMENT",
          description: "Proven track record as Business Development in multiple vertical industry from Government, Local own state & group conglomerates with the involvement in Lifecycle Information Management implementation",
          socialList: [
            {
              icon: "fa-facebook-f",
              url: "https://www.facebook.com/",
            },
            {
              icon: "fa-linkedin-in",
              url: "https://www.linkedin.com/",
            },
            {
              icon: "fa-twitter",
              url: "https://twitter.com/",
            },
          ],
        },
        {
          id: 2,
          src: require("../../assets/images/blog/Pak Ben.png"),
          title: "BENY PRABOWO",
          designation: "BUSINESS DEVELOPMENT",
          description: "Extensive experience in FAST DATA Integration and Supply Chain. Ex-Dattabot VP Big Data. Understands all necessary underlying technology for data analytics and security in FSI, Retail, FMCG and other industry. Experienced in implementation of strategic IT and analytics-based digital transformation",
          socialList: [
            {
              icon: "fa-facebook-f",
              url: "https://www.facebook.com/",
            },
            {
              icon: "fa-linkedin-in",
              url: "https://www.linkedin.com/",
            },
            {
              icon: "fa-twitter",
              url: "https://twitter.com/",
            },
          ],
        },
        {
          id: 3,
          src: require("../../assets/images/team/team-03.jpg"),
          title: "ENDY LAMBEY",
          designation: "SOLUTION ADVISOR",
          description: "Proven track record as Technical Team Leader on multiple complex Enterprise Data Warehouse projects, especially IBM Banking Data Warehouse model-based projects. Expertise in Physical Data Modeling, Data Integration Architecture and Development, Master Data Management methodology, Data Governance, and Full Lifecycle Information Management implementation",
          socialList: [
            {
              icon: "fa-facebook-f",
              url: "https://www.facebook.com/",
            },
            {
              icon: "fa-linkedin-in",
              url: "https://www.linkedin.com/",
            },
            {
              icon: "fa-twitter",
              url: "https://twitter.com/",
            },
          ],
        },
        {
          id: 4,
          src: require("../../assets/images/blog/Pak Arief.png"),
          title: "ARIEF HASANI",
          designation: "TECHNOLOGY & OPERATION DELIVERY",
          description: "Technology Head who familiar with Design/architect, develop and implement multi tiers enterprise or multi stacks cloud native solutions, employing but not limited to Enterprise Integration Patterns, from ETL, message queue to CQRS event sourcing and recently exploring Machine Learning and Artificial Intelligent. In storage I am familiar with SQL RDBMS from various vendors, In Memory data Grid and Cache as well as Client Server/Embedded NoSQL",
          socialList: [
            {
              icon: "fa-facebook-f",
              url: "https://www.facebook.com/",
            },
            {
              icon: "fa-linkedin-in",
              url: "https://www.linkedin.com/",
            },
            {
              icon: "fa-twitter",
              url: "https://twitter.com/",
            },
          ],
        },
      ],
    };
  },
};
</script>
<style scoped>
.kartu-nama {
  position: relative;
}
.foto-kartu-nama {
  position: absolute;
  transform: translate(-50%,-0%);
  top: -25%;
  left: 50%;
}
.foto-kartu-nama2 {
  position: sticky;
}


</style>
