<template>
  <div>
    <v-container class="px-8 pb-8 mt-3 rounded-xl">
      <v-row class="pa-0">
        <!-- Start Single Team Area  -->
        <v-col cols="12" class="d-flex justify-center pa-0">
          <h4 class="heading-title">Business Application layer – the use cases</h4>
        </v-col>
        <v-col
          lg="4"
          md="4"
          sm="12"
          cols="12"
        >
          <div class="team-static">
            <div class="thumbnail">
              <img src="../../assets/images/service/increase-revenue.png" alt="Team Images" />
            </div>
          </div>
        </v-col>
        <v-col
          lg="4"
          md="4"
          sm="12"
          cols="12"
        >
          <div class="team-static">
            <div class="thumbnail">
              <img src="../../assets/images/service/decrese-costs.png" alt="Team Images" />
            </div>
          </div>
        </v-col>
        <v-col
          lg="4"
          md="4"
          sm="12"
          cols="12"
        >
          <div class="team-static">
            <div class="thumbnail">
              <img src="../../assets/images/service/mitigate-risk.png" alt="Team Images" />
            </div>
          </div>
        </v-col>
        <!-- End Single Team Area  -->
      </v-row>
    </v-container>

    <v-container fluid class="d-flex flex-row mt-5 rounded-lg bg_color--8">
      <v-row>
        <v-col cols="12" class="d-flex justify-center pa-0 pt-3">
          <h4 class="heading-title">Data Infrastructure layer</h4>
        </v-col>
        <v-col cols="12">
          <ul class="brand-style-2 d-flex flex-nowrap">
            <li class="d-flex flex-column justify-center">
              <img src="../../assets/images/icons/data-pipelines.png" alt="Logo Images" />
              <span>Data Pipelines</span>
            </li>
            <li class="d-flex flex-column justify-center">
              <img src="../../assets/images/icons/messaging.png" alt="Logo Images" />
              <span>Messaging</span>
            </li>
            <li class="d-flex flex-column justify-center">
              <img src="../../assets/images/icons/microservice.png" alt="Logo Images" />
              <span class="stream-processing">Microservice/Event Sourcing</span>
            </li>
            <li class="d-flex flex-column justify-center">
              <img src="../../assets/images/icons/stream-processing.png" alt="Logo Images" />
              <span class="stream-processing">Stream Processing</span>
            </li>
            <li class="d-flex flex-column justify-center">
              <img src="../../assets/images/icons/data-integration.png" alt="Logo Images" />
              <span>Data Integration</span>
            </li>
            <li class="d-flex flex-column justify-center">
              <img src="../../assets/images/icons/streamimng-etl.png" alt="Logo Images" />
              <span>Streaming ETL</span>
            </li>
            <li class="d-flex flex-column justify-center">
              <img src="../../assets/images/icons/aggregation.png" alt="Logo Images" />
              <span>Log Aggregation</span>
            </li>
          </ul>
        </v-col>
      </v-row>
      <!-- End of Data Infra  -->
    </v-container>
      
    <v-container class="px-8 mt-5 rounded-lg bg_color--8">
      <v-row>
        <v-col cols="12" class="d-flex justify-center pa-0 pt-3">
          <h4 class="heading-title">Cyber Security</h4>
        </v-col>
        <v-col cols="12">
          <ul class="brand-style-2">
            <li class="d-flex flex-column justify-center">
              <img src="../../assets/images/icons/data-pipelines.png" alt="Logo Images" />
              <span>Data Pipelines</span>
            </li>
            <li class="d-flex flex-column justify-center">
              <img src="../../assets/images/icons/messaging.png" alt="Logo Images" />
              <span>Messaging</span>
            </li>
          </ul>
        </v-col>
      </v-row>
      <!-- End of Data Infra  -->
    </v-container>
  </div>
  </template>
  
  <script>
  import Brand from "../../components/brand/Brand.vue";
  export default {
    components: {
      Brand,
    },
    data() {
      return {
        teamContent: [
          {
            id: 1,
            src: require("../../assets/images/team/team-01.jpg"),
            title: "Jone Due",
            designation: "Sr. Web Developer",
            socialList: [
              {
                icon: "fa-facebook-f",
                url: "https://www.facebook.com/",
              },
              {
                icon: "fa-linkedin-in",
                url: "https://www.linkedin.com/",
              },
              {
                icon: "fa-twitter",
                url: "https://twitter.com/",
              },
            ],
          },
          {
            id: 2,
            src: require("../../assets/images/team/team-03.jpg"),
            title: "Fatima-Asrafi",
            designation: "Front-end Engineer",
            socialList: [
              {
                icon: "fa-facebook-f",
                url: "https://www.facebook.com/",
              },
              {
                icon: "fa-linkedin-in",
                url: "https://www.linkedin.com/",
              },
              {
                icon: "fa-twitter",
                url: "https://twitter.com/",
              },
            ],
          },
          {
            id: 3,
            src: require("../../assets/images/team/team-05.jpg"),
            title: "Al-Amin Bali",
            designation: "Sr. Graphic Designer",
            socialList: [
              {
                icon: "fa-facebook-f",
                url: "https://www.facebook.com/",
              },
              {
                icon: "fa-linkedin-in",
                url: "https://www.linkedin.com/",
              },
              {
                icon: "fa-twitter",
                url: "https://twitter.com/",
              },
            ],
          },
        ],
      };
    },
  };
  </script>
  <style>
    li > span.stream-processing{
      font-size: 12px !important;
    }
    li > span.stream-processing:hover{
      font-size: 12px !important;
    }
  </style>
  