<template>
  <div>
    <!-- Start Header Area -->
    <HeaderOnePageTwo class="d-xs-none d-sm-none d-md-flex hidden-mobile" />
    <HeaderOnePageTwoMobile class="d-flex d-xs-flex d-sm-flex d-md-none" />
    <!-- End Header Area -->

    <!-- Start of Slider Area -->
    <div class="rn-slider-area interactive-slider">
      <div
        class="slide slide-style-2 slider-video-bg d-flex align-center justify-center"
      >
        <SliderSibernetik />

        <div class="photo-background hidden-mobile">
          <div
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-image: linear-gradient(
                to bottom,
                rgba(42, 42, 46, 0.75) 50%,
                rgb(41, 41, 44)
              );
            "
          ></div>
          <video
            muted=""
            autoplay=""
            playsinline=""
            loop=""
            poster=""
            style="width: 100%; height: auto; background-color: black"
          >
            <source src="../../assets/images/bg/background-vid2.mp4" />
          </video>
        </div>
        <div class="photo-background hidden-md-and-up">
          <video
            muted=""
            autoplay=""
            playsinline=""
            loop=""
            poster=""
            style="height: 100%"
          >
            <source src="../../assets/images/bg/background-vid2.mp4" />
          </video>
        </div>
        <!--         
        <div class="video-background">
          <span>
            <video muted="" autoplay="" playsinline="" loop="" poster="">
              <source src="../../assets/images/bg/background-vid2.mp4" />
            </video>
          </span>
        </div> -->
      </div>
    </div>
    <!-- End of Slider Area -->

    <!-- Start Slider Area -->
    <!-- <div class="slide slide-style-2Limit slider-video-bg d-flex align-center justify-center overlay-fix" style="position: relative; height: 100%; margin:0;"
      data-black-overlay="1">

      <SliderSibernetik />

      <div class="photo-background">
          <img src="../../assets/images/bg/bc-technology4.png" class="foto-homepage" alt=""/>
      </div>
    </div> -->
    <!-- End Slider Area -->

    <!-- <div class="slide slide-style-2Limit slider-video-bg d-flex align-center justify-center overlay-fix" style="position: relative; height: 100%; margin:0;"
      data-black-overlay="1">

      <SliderSibernetik />

      <div class="photo-background">
          <img src="../../assets/images/bg/bc-technology4.png" class="foto-homepage" alt=""/>
      </div>
    </div> -->

    <!-- <div
    class="slide slide-style-2Limit fullscreen d-flex align-center background-foto2"
    data-black-overlay="3"
  >
    <v-container>
      <div class="inner">
        <SliderSibernetik />
      </div>
    </v-container>
  </div> -->

    <!-- Start Blog Area  -->
    <!-- <div class="section rn-blog-area rn-section-gap bg_color--5" id="blog">
      <v-container class="section-title section-title--2 text-center d-flex flex-column justify-center mb-10">
        <h4 class="heading-title mb-5 text-h3 title-light">We Transform The Customer Experience</h4>
        <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
      </v-container>
      <v-container>
        <WeTransformTheCustomer/>
      </v-container>
    </div> -->
    <!-- End Blog Area  -->

    <!-- Start Service Area -->
    <!-- <div class="service-area ptb--80  bg_image bg_image--3" id="opening"> -->
    <div class="service-area ptb--80 bg_color--5" id="whySibernetik">
      <v-container class="fade-in">
        <v-row>
          <v-col lg="4" md="4" sm="12" cols="12">
            <div
              class="section-title text-left mt--30 mt_md--5 mt_mobile--5 mb_mobile--10"
            >
              <h2 class="heading-title">Who we are</h2>
              <p class="ml-3 whoweare-ket">
                Established since 2017, Sibernetik is an Indonesia Data
                Streaming Analytics & Security Firm. Lean team consist of
                individuals mastering state-of-the-art cross-industry enterprise
                tech: Realtime Data Replication, Realtime Data Streaming,
                Realtime Database, Microservices and Security Sensor.
              </p>
              <!-- <p class="ml-3 whoweare-ket">
                Experience, passionate and dynamic professionals with regular refreshed necessary knowledge and skill set. We combine logic, creativity and curiosity to build, solve, and create.
              </p> -->
              <p class="ml-3 whoweare-ket">
                Every day, we help clients engage with new technology paradigms,
                creatively building solutions that solve their most pressing
                business challenges and move them to the forefront of their
                industry.
              </p>
              <!-- <div class="service-btn">
                <a class="btn-transparent rn-btn-dark" href="/service"
                  ><span class="text">Data streaming is our signature</span></a 
                >
              </div> -->
            </div>
          </v-col>
          <v-col lg="8" md="8" sm="12" cols="12" class="mt_md--50">
            <ServiceEight />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Service Area -->

    <!-- Start feature Area  -->
    <div class="rn-team-area rn-section-gapsiber background-foto1">
      <v-container
        class="fade-in"
        style="margin-top: 20px; margin-bottom: 20px"
      >
        <div class="section-title section-title--2siber mb-10">
          <h2 class="exist-title" style="font-size: 50px !important">
            Technologies Drive Much-Needed Business Outcomes
          </h2>
        </div>
        <v-row class="service-one-wrapper">
          <v-col sm="12" md="4" v-for="(item, i) in features" :key="i">
            <div class="d-flex flex-column kolom-fitur pa-2">
              <img
                style="
                  width: 60px !important;
                  filter: brightness(0) invert(1);
                  margin-bottom: 20px;
                  align-items: center;
                "
                :src="item.iconFeatures"
              />
              <span
                style="
                  text-align: left;
                  font-weight: 300;
                  color: #ffff;
                  font-size: 24px;
                "
              >
                {{ item.titleFeatures }}
              </span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End feature Area  -->

    <!-- Start blog Area  -->
    <!-- <div class="rn-blog-area rn-section-gap bg_color--5" id="managementTeam">
      <v-container class="fade-in">
        <v-row>
          <v-col cols="12">
            <div class="section-title section-title--2 text-center mb--20 mb_sm--0 md_sm--0">
              <h4 class="heading-title">Our Tech & Industry Professionals</h4>
              <h3 class="heading-title mb-5">
                Experience, passionate and dynamic professionals with regular refreshed necessary knowledge and skill set.
                We combine logic, creativity and curiosity to build, solve, and create.
              </h3>
              <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
            </div>
          </v-col>
        </v-row>
        <Blog />
      </v-container>
    </div> -->
    <!-- Start blog Area  -->

    <!-- Start About Area  -->
    <!-- <div class="about-area rn-section-gap bg_color--5">
      <div class="about-wrapper">
        <v-container>
          <div class="py-4">
            <TabThree/>
          </div>
        </v-container>
      </div>
    </div> -->
    <!-- End About Area  -->

    <!-- Start About Area  -->
    <!-- <div class="rn-about-area rn-section-gap bg_color--5 flex flex-column justify-start align-start"  id="solution">
      <v-container class="d-flex flex-column justify-center align-center">
        <div class="section-title section-title--2 text-center mb-16">
          <h4 class="text-h4 font-weight-bold">We Transform Customer Experience</h4>
        </div>
        <AboutSiber/>
      </v-container>
    </div> -->
    <!-- End About Area  -->

    <!-- Start Old Partners Area  -->
    <div class="section rn-blog-area rn-section-gap bg_color--5" id="partners">
      <v-container class="fade-in" style="height: fit-content">
        <div
          class="section-title section-title--2 text-center mb--20 mb_sm--0 mb_md--0"
        >
          <h4 class="heading-title">Principal Products</h4>
          <h3 class="heading-title mb-5">
            Data Infrastructure Platform & Security
          </h3>
          <hr
            color="#dd8732"
            width="200px;"
            style="margin: auto; border-top: 4px solid #dd8732"
          />
          <p
            style="text-align: center; font-size: 24px !important"
            class="my-8"
          >
            We team with world-class partners to bring your Data, Cloud, Digital
            Transformation, Security and other technology solutions to life. We
            can further pinpoint precise solutions with industry and use
            case-specific partners to drive your business forward.
          </p>
        </div>
        <div>
          <PrincipalProducts />
        </div>
      </v-container>
    </div>
    <!-- End Old Partners Area  -->

    <!-- Start New Partners Area -->
    <!-- <div class="rn-portfolio-area rn-section-gap bg_color--1" id="partners">
      <div class="portfolio-sacousel-inner pb--30">
        <v-container>
          <v-row>
            <v-col lg="12">
              <div class="section-title section-title--2 text-center mb--20 mb_sm--0 mb_md--0">
                <h4 class="heading-title">Principal Products</h4>
                <h3 class="heading-title mb-5">
                  Data Infrastructure Platform & Security
                </h3>
                <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
                <p style="text-align: center;" class="my-8">Sibernetik strives to design and deliver the right real-time data streaming solution to your company.<br><b style="font-weight: 700;">Preffered Local Partner</b> of best product principles in real-time data streaming ecosystem,<br/> trusted by half of the FORTUNE 100</p>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <PortfolioPartnersPricipal />
        </v-container>
      </div>
    </div> -->
    <!-- End New Partners Area -->

    <!-- Start Siberlabs  -->
    <div class="rn-pricing-plan-area rn-section-gap bg_color--5" id="siberlabs">
      <v-container class="fade-in">
        <v-row>
          <v-col cols="12" class="mb-0 pb-0">
            <div class="section-title section-title--2 text-center mb--20">
              <h4 class="heading-title">Siber Labs</h4>
              <h3 class="heading-title mb-5">
                Siber Labs is the place for you<br />to go in-depth about our
                product cycle
              </h3>
              <hr
                color="#dd8732"
                width="200px;"
                style="margin: auto; border-top: 4px solid #dd8732"
              />
              <p
                style="text-align: center; font-size: 24px !important"
                class="my-8"
              >
                This also helps you to easily find out the best scenario for the
                solution that would be implemented in your environment.
              </p>
            </div>
            <p
              style="
                text-align: center;
                font-weight: 700;
                font-size: 24px !important;
              "
              class="mt-2"
            >
              Our Demo Environment Line-up.<br />Speak to an expert. Find out
              what Sibernetik can do for you.
            </p>
          </v-col>
        </v-row>
        <!-- End row -->
        <v-row class="d-flex justify-center">
          <!-- <v-col lg="3" md="3" sm="12" cols="12">
            <div
              class="section-title text-left mt--30 mt_md--5 mt_mobile--5 mb_mobile--10"
            >
              <h2 class="heading-title">Our<br>Demo Environment Line-up:</h2>
            </div>
          </v-col> -->
          <v-col lg="10" md="10" sm="12" cols="12" class="mt_md--50">
            <PricingPlanSiber />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Siber Labs  -->

    <!-- Start Recognitions & Awards Area -->
    <div
      class="rn-portfolio-area rn-section-gap bg_color--1"
      id="recognitionAwards"
      style=""
    >
      <div class="portfolio-sacousel-inner pb--30">
        <v-container class="fade-in">
          <v-row>
            <v-col lg="12">
              <div class="section-title section-title--2 text-center mb--20">
                <h3 class="heading-title mb-5">Awards and recognition</h3>
                <hr
                  color="#dd8732"
                  width="200px;"
                  style="margin: auto; border-top: 4px solid #dd8732"
                />
                <p
                  style="text-align: center; font-size: 24px !important"
                  class="my-8"
                >
                  Over the years, we have been recognized for our outstanding
                  service, industry leadership,<br />company culture and so much
                  more.
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <PortfolioAwards class="fade-in" />
      </div>
    </div>
    <!-- End Recognitions & Awards Area -->

    <!-- Start client Area  -->
    <div class="rn-team-area rn-section-gapsiber bg_color--1" id="clients">
      <OurPreviousInvolvement class="fade-in" />
    </div>
    <!-- End client Area  -->

    <!-- Start blog Area  -->
    <div class="rn-blog-area rn-section-gap bg_color--5" id="coreValues">
      <v-container class="fade-in">
        <v-row>
          <v-col cols="12">
            <div
              class="section-title section-title--2 text-center mb--20 mb_sm--0 md_sm--0"
            >
              <h4 class="heading-title">Our core values</h4>
              <h3 class="heading-title mb-5">
                <!-- Experience, passionate and dynamic professionals with regular refreshed necessary knowledge and skill set -->
                To embody Engineering First, we approach each conversation, each
                project, and each challenge with our values of curiosity,
                self-driven, collaboration, impact in mind.
              </h3>
              <hr
                color="#dd8732"
                width="200px;"
                style="margin: auto; border-top: 4px solid #dd8732"
              />
            </div>
          </v-col>
        </v-row>
        <!-- End row -->
        <v-row>
          <!-- Start Blog Area  -->
          <v-col
            lg="3"
            md="3"
            sm="6"
            cols="12"
            v-for="(item, i) in csciContent"
            :key="i"
          >
            <div class="d-flex justify-center align-center">
              <!-- <div class="card-image">
                <img class="w-100" :src="item.item" alt="Blog Images" />
              </div> -->
            </div>
            <div
              class="card-header d-flex flex-column align-center"
              style="margin-top: 50px !important"
            >
              <span
                class="span-title mb-10 d-flex flex-column align-center justify-center"
                style="
                  border-radius: 10px;
                  color: #fff !important;
                  background-image: linear-gradient(90deg, #ff8008, #ffc837);
                  font-size: 20px;
                  width: 200px;
                  height: 200px;
                "
              >
                <v-icon style="font-size: 200px; color: #fff !important">{{
                  item.item
                }}</v-icon>
              </span>
              <h4 class="h-name" style="font-size: 24px">
                <strong>{{ item.desc }}</strong>
              </h4>
            </div>
          </v-col>
          <!-- End Blog Area  -->
        </v-row>
      </v-container>
    </div>
    <!-- Start blog Area  -->
    <!-- Start Footer Area  -->
    <Footer />
    <!-- End Footer Area  -->
  </div>
</template>
  
<script>
import HeaderOnePageTwo from "../../components/header/HeaderOnePageTwoSiber.vue";
import HeaderOnePageTwoMobile from "../../components/header/HeaderOnePageTwoSiberMobile.vue";
import SliderFour from "../../components/slider/SliderFour";
import AboutFour from "../../components/about/AboutFour";
import ServiceSeven from "../../components/service/ServiceSeven.vue";
import EnterpriseDataPlatform from "../../components/counter/CounterThree.vue";
import WhatMattersNow from "../../components/counter/CounterFour.vue";
import DoMoreWUrData from "../../components/team/TeamFive.vue";
import WeEnableEnterprises from "../../components/counter/CounterFive.vue";
import PublicRecognitions1 from "../../components/portfolio/Portfolio1";
import PublicRecognitions2 from "../../components/portfolio/Portfolio2";
import PublicRecognitions3 from "../../components/portfolio/Portfolio3";
import PrincipalProducts from "../../components/blog/BlogFour.vue";
// import Brand from "../../components/brand/Brand";
import Contact from "../../components/contact/ContactSiber.vue";
import Footer from "../../components/footer/Footer.vue";
import SliderSibernetik from "../../components/slider/SliderSibernetik.vue";
import WeTransformTheCustomer from "../../components/blog/BlogSiber.vue";
import Team from "../../components/team/TeamSiber";
import AboutSiber from "../../components/about/AboutSiber.vue";
import OurPreviousInvolvement from "../../components/brand/BrandSiber.vue";
import ServiceEight from "../../components/service/ServiceEight";
import TabThree from "../../components/tabs/TabThree.vue";
import Blog from "../../components/blog/BlogSiber2.vue";
// import PortfolioAwards from "../../components/portfolio/PortfolioAwards";
import PortfolioAwards from "../../components/portfolio/PortfolioAwards.vue";
import PricingPlanSiber from "../../components/pricing-plan/PricingPlanSiber";
import PortfolioPartnersPricipal from "../../components/portfolio/PortfolioPartnersPricipal";
// import PatternOne form "../../components/";

export default {
  name: "SiberWeb",
  components: {
    HeaderOnePageTwo,
    HeaderOnePageTwoMobile,
    PortfolioPartnersPricipal,
    PortfolioAwards,
    SliderFour,
    AboutFour,
    ServiceSeven,
    ServiceEight,
    TabThree,
    WhatMattersNow,
    EnterpriseDataPlatform,
    DoMoreWUrData,
    WeEnableEnterprises,
    PublicRecognitions1,
    PublicRecognitions2,
    PublicRecognitions3,
    PrincipalProducts,
    // Brand,
    Contact,
    Footer,
    SliderSibernetik,
    WeTransformTheCustomer,
    Team,
    AboutSiber,
    PricingPlanSiber,
    OurPreviousInvolvement,
    Blog,
  },
  data: () => ({
    csciContent: [
      {
        item: "C",
        desc: "Curiousity",
      },
      {
        item: "S",
        desc: "Self-Driven",
      },
      {
        item: "C",
        desc: "Collaboration",
      },
      {
        item: "I",
        desc: "Impact",
      },
    ],
    fadeInUp: false,
    features: [
      {
        iconFeatures: require("../../assets/images/icons/transaction.svg"),
        titleFeatures:
          "Data is the new currency of business success. Organizations with “better” data management and use it more effectively, win in the market.",
      },
      {
        iconFeatures: require("../../assets/images/icons/transactional-data.svg"),
        titleFeatures:
          "The digital realm is as important as the physical world in how business is “transacted”.",
      },
      {
        iconFeatures: require("../../assets/images/icons/satisfaction.svg"),
        titleFeatures:
          "Emerging technologies and trends can enhance products and improve customer value, retention and revenue. ",
      },
    ],
  }),
  methods: {
    handleScroll() {
      const element = document.getElementById("target-element"); // Replace 'target-element' with the ID of the element you want to fade in
      const elementPosition = element.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (elementPosition < windowHeight) {
        this.fadeInUp = true;
      }
    },
  },
  mounted() {
    // Select the elements with the fade-in class
    const fadeElements = document.querySelectorAll(".fade-in");

    // Function to check if an element is visible in the viewport
    const isElementVisible = (element) => {
      const rect = element.getBoundingClientRect();
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      return (
        (rect.top >= 0 && rect.top <= windowHeight) ||
        (rect.bottom >= 0 && rect.bottom <= windowHeight)
      );
    };

    // Function to handle the scroll event
    const handleScroll = () => {
      fadeElements.forEach((element) => {
        if (
          isElementVisible(element) &&
          !element.classList.contains("fade-in-up")
        ) {
          element.classList.add("fade-in-up");
        }
      });
    };

    // Attach the handleScroll function to the scroll event
    window.addEventListener("scroll", handleScroll);
  },
};
</script>
  
<style scoped>
.whoweare-ket {
  font-family: "Outfit", sans-serif !important;
  font-weight: 400;
  font-size: 24px !important;
}

.kolom-fitur {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: "Outfit", sans-serif !important;
  /* border: 1px solid white;
      border-radius: 5px; */
}

.foto-homepage {
  position: absolute;
  height: 100%;
  /* width: auto; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.feather-menu {
  color: #1d1d24;
  background-color: #5f88be;
}

.background-foto1 {
  background-color: #152939e3;
  background-image: url(../../assets/images/bg/bc-technology5.png);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.background-foto2 {
  background-image: url(../../assets/images/bg/bc-technology4.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.content-coption {
  font-family: "Urbanist", sans-serif;
}

.contact-us {
  background-image: url(../../assets/images/bg/bg-image-38.png);
}

.insight-color {
  height: 100%;
  backdrop-filter: blur(2px) saturate(100%);
  -webkit-backdrop-filter: blur(2px) saturate(100%);
  border: 1px solid rgba(255, 255, 255, 0.125);
  filter: drop-shadow(0 30px 10px rgba(0, 0, 0, 0.125));
}

/* Animation */
@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up {
  opacity: 0;
  animation: fade-in-up 1.2s forwards;
}

#recognitionAwards {
  background-color: #ffffff;
  background-image: repeating-radial-gradient(
      circle at 0 0,
      transparent 0,
      #ffffff21 9px
    ),
    repeating-linear-gradient(#15293955, #15293921);
}

#siberlabs {
  background-color: #ffffff;
  background-image: linear-gradient(#1529391a 0.9px, transparent 0.9px),
    linear-gradient(to right, #1529391a 0.9px, #ffffff1a 0.9px);
  background-size: 18px 18px;
}
</style>  