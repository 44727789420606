<template>
  <div>
    <v-expansion-panels
      accordion
      flat
      v-model="panel"
      mandatory
      class="about-expension-panels"
    >
      <v-expansion-panel v-for="(item, i) in accordionContent" :key="i">
        <v-expansion-panel-header hide-actions>
          <h5 class="text-h5 heading-expantion">{{item.title}}</h5>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <h6 class="text-h6">{{item.subtitle}}</h6>
          <table>
            <tr>
              <td class="centang"><v-img :src="checkList"/></td>&nbsp;
              <td>
                <span class="text-body-1">{{item.content1}}</span>
              </td>
            </tr>
            <tr>
              <td class="centang"><v-img :src="checkList"/></td>&nbsp;
              <td>
                <span class="text-body-1">{{item.content2}}</span>
              </td>
            </tr>
            <tr>
              <td class="centang"><v-img :src="checkList"/></td>&nbsp;
              <td>
                <span class="text-body-1">{{item.content3}}</span>
              </td>
            </tr>
          </table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import checkList from "../../assets/images/icons/checkList/check_23.png"

export default {
  components: {
    // checkList,
  },
  data() {
    return {
      checkList:checkList,
      panel: 0,
      accordionContent: [
        {
          title: "Sibernetik Owned Propriatery Solution",
          subtitle: "SaaS solution and deliver compeling data streaming & cyber security solutions to enterprises",
          content1: "Raltime inquiry engine. Rapidly transform digital services to meet customers’ rising expectations for highly secure, personalized and valuable experiences.",
          content2: "Near-space (Enterprise) - Situational Awareness.",
          content3: "Realtime Geospatial Analytics.",
        },
        {
          title: "Consulting & System Integration Solutions",
          subtitle: "To enable the existing enterprise monolithic legacy system into event driven microservices (OLTP: AS400, Mainframe, SAP, Oracle, Ms SQL, DB2, etc). To build active intelligence capability to transform the existing data insight into action",
          content1: "Qlik Active Intelligence. Embedded data & analytics services to deliver actionable insight",
          content2: "Native Real time Streaming. Combining Qlik Replicate, Confluent Platform and Imply to deliver native reatlime analytics solutions.",
          content3: "	Stream Processing. Combining Qlik Replicate, Confluent Platform and optimizing Apache Flink and stream processing to deliver realtime transformation to support realtime reporting & notification",
        },
      ],
    };
  },
};
</script>
<style scoped>
.heading-expantion {
  font-weight: 700;
  line-height: 20px;
}
.centang {
  vertical-align: top;
  min-width: 17px;
  padding-top: 5px;
 }
</style>