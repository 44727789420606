<template>
  <v-row>
    <!-- Start Blog Area  -->
    <v-col lg="3" md="3" sm="6" cols="12" v-for="(item, i) in blogContent" :key="i">
      <!-- <div class="blog blog-style--1staff" v-on:click="openDialog = true; dialogContent = item;">
        <article class="card pa-0">
          <figure class="card-image">
            <img class="w-100" :src="item.src" alt="Blog Images" />
          </figure>
          <div class="card-header d-flex flex-column align-start">
            <span class="span-title px-2 mb-1"
              :style="`border-radius: 10px; color: #fff; background-color:${item.color}`">{{ item.title }}</span>
            <h4 class="h-name">
              {{ item.name }}
            </h4>
          </div>
        </article>
      </div> -->
      <div class="blog blog-style--1staff my-2" v-on:click="openDialog = true; dialogContent = item;">
        <div class="card-image">
          <img class="w-100" :src="item.src" alt="Blog Images" />
        </div>
        <div class="card-header d-flex flex-column align-start">
          <span class="span-title px-2 mb-1"
            :style="`border-radius: 10px; color: #fff; background-color:${item.color}`">{{ item.title }}</span>
          <h4 class="h-name">
            {{ item.name }}
          </h4>
        </div>
        <v-container class="kacapembesar d-flex justify-center align-center" style="padding: auto;">
          <span class="mdi mdi-magnify">Click here..</span>
        </v-container>
      </div>
    </v-col>
    <v-dialog v-model="openDialog" width="auto">
      <v-card
        class="mx-auto"
        max-width="400"
        light
        style="border-radius: 10px;"
        :color="dialogContent.color"
      >
        <v-card-title>
          <!-- <span class="text-h6 font-weight-light">Capability</span> -->
        </v-card-title>

        <v-card-text class="text-h5 font-weight-bold">
          "{{ dialogContent.bio }}"
        </v-card-text>

        <v-card-actions>
          <v-list-item class="grow">
            <v-list-item-avatar color="grey darken-3">
              <v-img
              :src="dialogContent.src"/>
            </v-list-item-avatar>

            <v-list-item-content class="d-flex">
              <v-list-item-title class="subtitle-2 font-weight-bold">{{ dialogContent.name }}</v-list-item-title>
            </v-list-item-content>

            <v-row
              align="center"
              justify="end"
            >
              <a :href="dialogContent.url1" target="_blank">
                <v-icon class="mr-2">
                  mdi-linkedin
                </v-icon>
              </a>
              <a :href="dialogContent.url2" target="_blank">
                <v-icon class="mr-5">
                  mdi-instagram 
                </v-icon>
              </a>
            </v-row>
          </v-list-item>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Blog Area  -->
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      openDialog: false,
      dialogContent: {},
      blogContent: [
        {
          id: 1,
          src: require("../../assets/images/blog/Pak Stephen.png"),
          title: "CEO",
          name: "Stephen Y.S.",
          bio: "Proven track record as Business Development in multiple vertical industry from Government, Local own state & group conglomerates with the involvement in Lifecycle Information Management implementation.",
          color: "#B92025",
          url1: "https://www.linkedin.com/in/stephen-yin-soen-01b49217",
          url2: "https://www.instagram.com/sibernetik_id/",
        },
        {
          id: 2,
          src: require("../../assets/images/blog/Pak Ben.png"),
          title: "CBO",
          name: "Beny Prabowo",
          bio: "Extensive experience in FAST DATA Integration and Supply Chain. Ex-Dattabot VP Big Data. Understands all necessary underlying technology for data analytics and security in FSI, Retail, FMCG and other industry. Experienced in implementation of strategic IT and analytics-based digital transformation.",
          color: "#5FA143",
          url1: "https://www.linkedin.com/in/beny-prabowo-23b14b24",
          url2: "https://www.instagram.com/sibernetik_id/",
        },
        {
          id: 3,
          src: require("../../assets/images/blog/Pak Arief.png"),
          title: "CTO",
          name: "Arief Hasani",
          bio: "Technology Head whose familiar with Design/Architect, develop and implement of multi tiers enterprise or multi stacks cloud-native solutions, various SQL RDBMS, In Memory Data Grid and Cache, and Client Server/Embeded NoSQL. Employing Enterprise Integration Patterns, from ETL, message queue to CQRS event sourcing and recently exploring Machine Learning and Artificial Intelligent.",
          color: "#38BEEF",
          url1: "https://www.linkedin.com/company/sibernetik-integradata/",
          url2: "https://www.instagram.com/sibernetik_id/",
        },
        {
          id: 4,
          src: require("../../assets/images/blog/Pak Endy.png"),
          title: "Advisor",
          name: "Endy Lambey",
          bio: "Proven track record as Technical Team Leader on multiple complex Enterprise Data Warehouse projects, especially IBM Banking Data Warehouse model-based projects. Expertise in Physical Data Modeling, Data Integration Architecture and Development, Master Data Management methodology, Data Governance, and Full Lifecycle Information Management implementation.",
          color: "#6EC6A5",
          url1: "https://www.linkedin.com/in/lambey/",
          url2: "https://www.instagram.com/sibernetik_id/",
        },
      ],
    };
  },
};
</script>
