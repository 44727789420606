<template>  
<v-row class="service-one-wrapper mt--30">
    <!-- Start Single Service  -->
    <v-col
      xl="6"
      lg="6"
      cols="6"
      class="ur-bussiness d-flex bg_color--5"
    >
        <v-row class="d-flex justify-center">
            <v-col cols="6" >
                <div class="single-service service__style--1">
                    <div class="icon d-flex justify-center">
                        <img src="../../assets/images/icons/icon-11.png" alt="Creative Agency" />
                    </div>
                    <div class="content">
                        <p class="text-h6 heading-title" style="text-align:center; font-family: 'Urbanist', sans-serif;">
                            Be fast and agile in a highly competitive market
                        </p>
                    </div>
                </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="6">
                <div class="single-service service__style--1">
                    <div class="icon d-flex justify-center">
                        <img src="../../assets/images/icons/icon-12.png" alt="Creative Agency" />
                    </div>
                    <div class="content">
                        <p class="text-h6 heading-title" style="text-align:center; font-family: 'Urbanist', sans-serif;">
                            Personalize experiences to delight customers and increase loyalty
                        </p>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
            <v-col cols="6">
                <div class="single-service service__style--1">
                    <div class="icon d-flex justify-center">
                        <img src="../../assets/images/icons/icon-13.png" alt="Creative Agency" />
                    </div>
                    <div class="content">
                        <p class="text-h6 heading-title" style="text-align:center; font-family: 'Urbanist', sans-serif; !important">
                            Gain real time visibility into all aspects of the business
                        </p>
                    </div>
                </div>
            </v-col>
            
            <v-col cols="6">
                <div class="single-service service__style--1">
                    <div class="icon d-flex justify-center">
                        <img src="../../assets/images/icons/icon-14.png" alt="Creative Agency" />
                    </div>
                    <div class="content">
                        <p class="text-h6 heading-title" style="text-align:center; font-family: 'Urbanist', sans-serif; !important">
                            Reduce the cost and burden of IT operations
                        </p>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-col>
    <v-col
      xl="6"
      lg="6"
      cols="6"
      class="its-hard d-flex bg_color--1"
    >
        <v-row class="d-flex justify-center">
            <v-col cols="6" >
                <div class="single-service service__style--1">
                    <div class="icon d-flex justify-center">
                        <img src="../../assets/images/icons/icon-15.png" alt="Creative Agency" />
                    </div>
                    <div class="content">
                        <p class="text-h6 heading-title" style="text-align:center; font-family: 'Urbanist', sans-serif; !important">
                            The legacy architecture is monolithic and tightly coupled, slowing innovation
                        </p>
                    </div>
                </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="6" >
                <div class="single-service service__style--1">
                    <div class="icon d-flex justify-center">
                        <img src="../../assets/images/icons/icon-16.png" alt="Creative Agency" />
                    </div>
                    <div class="content">
                        <p class="text-h6 heading-title" style="text-align:center; font-family: 'Urbanist', sans-serif; !important">
                            Silos of data make it difficult to provide a consistent real-time experience
                        </p>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
            <v-col cols="6" >
                <div class="single-service service__style--1">
                    <div class="icon d-flex justify-center">
                        <img src="../../assets/images/icons/icon-17.png" alt="Creative Agency" />
                    </div>
                    <div class="content">
                        <p class="text-h6 heading-title" style="text-align:center; font-family: 'Urbanist', sans-serif; !important">
                            Applications built around legacy infrastructure are batch based and slow
                        </p>
                    </div>
                </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="6" >
                <div class="single-service service__style--1">
                    <div class="icon d-flex justify-center">
                        <img src="../../assets/images/icons/icon-18.png" alt="Creative Agency" />
                    </div>
                    <div class="content">
                        <p class="text-h6 heading-title" style="text-align:center; font-family: 'Urbanist', sans-serif; !important">
                            On-premises infrastructure increases costs and escalates complexity
                        </p>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
</template>

<script>
  export default {
    data() {
      return {
        serviceContent: [
          {
            src: require("../../assets/images/icons/icon-01.png"),
            title: "Interior Design",
            desc: `I throw myself down among the tall grass by the stream as I lie close to the earth.`,
          },
          {
            src: require("../../assets/images/icons/icon-02.png"),
            title: "Landcape Design",
            desc: `I throw myself down among the tall grass by the stream as I lie close to the earth.`,
          },
          {
            src: require("../../assets/images/icons/icon-03.png"),
            title: "Home Interior",
            desc: `I throw myself down among the tall grass by the stream as I lie close to the earth.`,
          },
          {
            src: require("../../assets/images/icons/icon-02.png"),
            title: "Landcape Design",
            desc: `I throw myself down among the tall grass by the stream as I lie close to the earth.`,
          },
          
        ],
      };
    },
  };
</script>
<style>
    .ur-bussiness {
        border-radius: 10%;
        -moz-box-shadow:    inset 0 0 10px #000000;
        -webkit-box-shadow: inset 0 0 10px #000000;
        box-shadow:         inset 0 0 10px #000000;
    }
    .its-hard {
        border-radius: 10%;
        -moz-box-shadow:    3px 3px 5px 6px #ccc;
        -webkit-box-shadow: 3px 3px 5px 6px #ccc;
        box-shadow:         3px 3px 5px 6px #ccc;
    }
</style>
