<template>
<v-row>
  <v-row>
    <!-- Start Single Counterup  -->
    <v-col cols="12" class="d-flex justify-center">
        <h3 class="heading-title">Your business wants to...</h3>
    </v-col>

    <v-col
      lg="3"
      md="3"
      sm="3"
      cols="12"
      v-for="(item, i) in selectedService"
      :key="item.id"
    >
      <div class="rn-counterup flex-column counterup_style--sol" @mouseover=" item.dark = true" @mouseleave=" item.dark = false">

        <div v-if="item.dark == false">
          <div class="d-flex justify-center">
            <img :src="yourBusinessWhite[i].icon" />
          </div>
          <p class="counter count ">
            {{ yourBusinessWhite[i].desc}}
          </p>
        </div>

        <div v-if="item.dark == false">
          <div class="d-flex justify-center">
            <img :src="yourBusinessDark[i].icon" />
          </div>
          <p class="counter count ">
            {{ yourBusinessDark[i].desc}}
          </p>
        </div>

      </div>
    </v-col>
    <!-- Start Single Counterup  -->
  </v-row>

  <v-row class="mt-10">
    <!-- Start Single Counterup  -->
    <!-- <v-col cols="12" class="d-flex justify-center">
        <h3 class="heading-title">It’s hard to deliver because...</h3>
    </v-col>
    <v-col
      lg="3"
      md="3"
      sm="3"
      cols="12"
    >
      <div class="rn-counterup flex-column counterup_style--1" >
        <div class="d-flex justify-center">
          <img src="../../assets/images/icons/icon-15.png" />
        </div>
        <p class="counter count ">
          The legacy architecture is monolithic and tightly coupled, slowing innovation
        </p>
      </div>
    </v-col>
    <v-col
      lg="3"
      md="3"
      sm="3"
      cols="12"
    >
      <div class="rn-counterup flex-column counterup_style--1" >
        <div class="d-flex justify-center">
          <img src="../../assets/images/icons/icon-16.png" />
        </div>
        <p class="counter count ">
          Silos of data make it difficult to provide a consistent real-time experience
        </p>
      </div>
    </v-col>
    <v-col
      lg="3"
      md="3"
      sm="3"
      cols="12"
    >
      <div class="rn-counterup flex-column counterup_style--1" >
        <div class="d-flex justify-center">
          <img src="../../assets/images/icons/icon-17.png" />
        </div>
        <p class="counter count ">
          Applications built around legacy infrastructure are batch based and slow
        </p>
      </div>
    </v-col>
    <v-col
      lg="3"
      md="3"
      sm="3"
      cols="12"
    >
      <div class="rn-counterup flex-column counterup_style--1" >
        <div class="d-flex justify-center">
          <img src="../../assets/images/icons/icon-18.png" />
        </div>
        <p class="counter count ">
          On-premises infrastructure increases costs and escalates complexity
        </p>
      </div>
    </v-col> -->
    <!-- Start Single Counterup  -->
  </v-row>
</v-row>
</template>
  
  <script>
    import VisibilitySensor from "vue-visibility-sensor";
    import countTo from "vue-count-to";
  
    export default {
      components: {
        countTo,
        VisibilitySensor,
      },
      data() {
        return {
          selectedService: [
            {
              id: "icon-11",
              dark: false,
            },
            {
              id: "icon-12",
              dark: false,
            },
            {
              id: "icon-13",
              dark: false,
            },
            {
              id: "icon-14",
              dark: false,
            },
          ],
          selectedServiceIndex: "",
          yourBusinessWhite: [
            {
              icon: require("../../assets/images/icons/icon-11.png"),
              desc: 'Be fast and agile in a highly competitive market',
            },
            {
              icon: require("../../assets/images/icons/icon-12.png"),
              desc: 'Personalize experiences to delight customers and increase loyalty',
            },
            {
              icon: require("../../assets/images/icons/icon-13.png"),
              desc: 'Gain real time visibility into all aspects of the business',
            },
            {
              icon: require("../../assets/images/icons/icon-14.png"),
              desc: 'Reduce the cost and burden of IT operations',
            },
          ],
          yourBusinessDark: [
            {
              icon: require("../../assets/images/icons/icon-11-2.png"),
              desc: 'Be fast and agile in a highly competitive market',
            },
            {
              icon: require("../../assets/images/icons/icon-12-2.png"),
              desc: 'Personalize experiences to delight customers and increase loyalty',
            },
            {
              icon: require("../../assets/images/icons/icon-13-2.png"),
              desc: 'Gain real time visibility into all aspects of the business',
            },
            {
              icon: require("../../assets/images/icons/icon-14-2.png"),
              desc: 'Reduce the cost and burden of IT operations',
            },
          ],
        };
      },
      methods: {
        onChange(isVisible) {
          if (isVisible) {
            this.status = true;
          }
        },
      },
    };
  </script>
  <style>
  </style>
  