<template>
  <div class="section-title">
    <h2 class="heading-title my-10" style="font-size: 42px !important; text-align: center !important;">
      Our previous involvements in recent years
    </h2>
    <!-- <p class="text-center">Our previous involvements in recent years</p> -->
    <!-- <div class="ml-4 mb-5">
      <h3 class="heading-title text-center">
        Private Sector
      </h3>
    </div>
    <v-row class="d-xs-none d-sm-none d-md-flex hidden-mobile mb-10">
      <v-col
        xl="12"
        lg="12"
        md="12"
        sm="6"
      >
        <ul class="brand-style-3">
          <li class="d-flex flex-column">
            <img src="../../assets/images/brand/BRI.png" alt="Logo Images" />
          </li>
          <li class="d-flex flex-column">
            <img src="../../assets/images/brand/btpn.png" alt="Logo Images" />
          </li>
          <li class="d-flex flex-column">
            <img src="../../assets/images/brand/btpnsyariah.png" alt="Logo Images" />
          </li>
          <li class="d-flex flex-column">
            <img src="../../assets/images/brand/pegadaian.png" alt="Logo Images" />
          </li>
          <li class="d-flex flex-column">
            <img src="../../assets/images/brand/sampoerna.png" alt="Logo Images" />
          </li>
        </ul>
      </v-col>
      <v-col
        sxl="12"
        lg="12"
        md="12"
        sm="6"
      >
        <ul class="brand-style-3">
          <li class="d-flex flex-column">
            <img src="../../assets/images/brand/mandiri.png" alt="Logo Images" />
          </li>
          <li class="d-flex flex-column">
            <img src="../../assets/images/brand/bni.png" alt="Logo Images" />
          </li>
          <li class="d-flex flex-column">
            <img src="../../assets/images/brand/commbank.png" alt="Logo Images" />
          </li>
          <li class="d-flex flex-column">
            <img src="../../assets/images/brand/ocbc.png" alt="Logo Images" />
          </li>
          <li class="d-flex flex-column">
            <img src="../../assets/images/brand/telkomsel.png" alt="Logo Images" />
          </li>
        </ul>
      </v-col>
      <v-col
        sxl="12"
        lg="12"
        md="12"
        sm="6"
      >
        <ul class="brand-style-3">
          <li class="d-flex flex-column">
            <img src="../../assets/images/brand/pins.png" alt="Logo Images" />
          </li>
          <li class="d-flex flex-column">
            <img src="../../assets/images/brand/sigma.png" alt="Logo Images" />
          </li>
          <li class="d-flex flex-column">
            <img src="../../assets/images/brand/telkom.png" alt="Logo Images" />
          </li>
          <li class="d-flex flex-column">
            <img src="../../assets/images/brand/sinarmas.png" alt="Logo Images" />
          </li>
          <li class="d-flex flex-column">
            <img src="../../assets/images/brand/sinarmasbank.png" alt="Logo Images" />
          </li>
        </ul>
      </v-col>
    </v-row>


    <div class="ml-4 mb-5">
      <h3 class="heading-title text-center">
        Public Sector
      </h3>
    </div>
    <v-row class="d-xs-none d-sm-none d-md-flex hidden-mobile mb-10">
      <v-col
        xl="12"
        lg="12"
        md="12"
        sm="6"
      >
        <ul class="brand-style-3">
          <li class="d-flex flex-column justify-start">
            <img src="../../assets/images/brand/pejaten.png" alt="Logo Images" />
          </li>
          <li class="d-flex flex-column justify-start">
            <img src="../../assets/images/brand/dephub.png" alt="Logo Images" />
          </li>  
          <li class="d-flex flex-column justify-start">
            <img src="../../assets/images/brand/nani.png" alt="Logo Images" />
          </li>
          <li class="d-flex flex-column justify-start">
            <img src="../../assets/images/brand/imigrasi.png" alt="Logo Images" />
          </li>
          <li class="d-flex flex-column justify-start">
            <img src="../../assets/images/brand/bssn.png" alt="Logo Images" />
          </li>
          <li class="d-flex flex-column justify-start">
            <img src="../../assets/images/brand/kartika.png" alt="Logo Images" />z
          </li>
        </ul>
      </v-col>
    </v-row> -->
    <!-- <div class="ticker-container">
      <div class="d-flex flex-row bungkus">
        <h3 class="ticker-text">Private Sector</h3>
        <div v-for="(item, index) in itemsbrand" :key="index" class="ticker-inner px-10">
          <img :src="item.imageUrl" :alt="item.altText" class="ticker-image">
        </div>
      </div>
    </div> -->
    <div class="runtext-container">
      <div class="main-runtext">
        <marquee onmouseover="this.stop();" onmouseout="this.start();">
          <div class="holder d-flex flex-row pt-10">
            <div class="d-flex flex-row align-center">
              <h3 class="ticker-text mr-10">Private Sector</h3>
              <div v-for="(item, index) in itemsbrand1" :key="index" class="d-flex flex-row">
                <img :src="item.imageUrl" :alt="item.altText" class="ticker-image mr-8">
              </div>
            </div>
            <div class="d-flex flex-row align-center ml-16">
              <h3 class="ticker-text mr-10">Public Sector</h3>
              <div v-for="(item, index) in itemsbrand2" :key="index" class="d-flex flex-row">
                <img :src="item.imageUrl" :alt="item.altText" class="mr-8" style="height: 85px; width: auto;">
              </div>
            </div>
          </div>
        </marquee>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      itemsbrand1: [ 
        {
          imageUrl: require("../../assets/images/brand/BRI.png"),
          altText: 'BRI Logo',
          text: 'Text 1',
        },
        {
          imageUrl: require("../../assets/images/brand/btpn.png"),
          altText: 'BTPN Logo',
          text: 'Text 2',
        },
        {
          imageUrl: require("../../assets/images/brand/btpnsyariah.png"),
          altText: 'BTPN Syaria Logo',
          text: 'Text 3',
        },
        {
          imageUrl:  require("../../assets/images/brand/pegadaian.png"),
          altText: 'Pegadaian Logo',
          text: 'Text 4',
        },
        // {
        //   imageUrl: require("../../assets/images/brand/sampoerna.png"),
        //   altText: 'Sampoerna Logo',
        //   text: 'Text 5',
        // },
        {
          imageUrl: require("../../assets/images/brand/mandiri.png"),
          altText: 'Mandiri Logo',
          text: 'Text 1',
        },
        {
          imageUrl: require("../../assets/images/brand/bni.png"),
          altText: 'BNI Logo',
          text: 'Text 2',
        },
        {
          imageUrl: require("../../assets/images/brand/commbank.png"),
          altText: 'Commbank Logo',
          text: 'Text 3',
        },
        {
          imageUrl:  require("../../assets/images/brand/ocbc.png"),
          altText: 'OCBC Logo',
          text: 'Text 4',
        },
        {
          imageUrl: require("../../assets/images/brand/telkomsel.png"),
          altText: 'Telkomsel Logo',
          text: 'Text 5',
        },
        {
          imageUrl: require("../../assets/images/brand/pins.png"),
          altText: 'Pins Logo',
          text: 'Text 1',
        },
        {
          imageUrl: require("../../assets/images/brand/sigma.png"),
          altText: 'Sigma Logo',
          text: 'Text 2',
        },
        {
          imageUrl: require("../../assets/images/brand/telkom.png"),
          altText: 'Telkom Logo',
          text: 'Text 3',
        },
        {
          imageUrl:  require("../../assets/images/brand/sinarmas.png"),
          altText: 'Sinarmas Logo',
          text: 'Text 4',
        },
        {
          imageUrl: require("../../assets/images/brand/sinarmasbank.png"),
          altText: 'SinarmasBank Logo',
          text: 'Text 5',
        },
      ],
      itemsbrand2: [ 
        {
          imageUrl: require("../../assets/images/brand/pejaten.png"),
          altText: 'BIN Logo',
          text: 'Text 1',
        },
        {
          imageUrl: require("../../assets/images/brand/dephub.png"),
          altText: 'DepHub Logo',
          text: 'Text 2',
        },
        {
          imageUrl: require("../../assets/images/brand/nani.png"),
          altText: '',
          text: 'Text 3',
        },
        {
          imageUrl:  require("../../assets/images/brand/imigrasi.png"),
          altText: 'Imigrasi Logo',
          text: 'Text 4',
        },
        {
          imageUrl: require("../../assets/images/brand/bssn.png"),
          altText: 'BBSN Logo',
          text: 'Text 5',
        },
        {
          imageUrl: require("../../assets/images/brand/kartika.png"),
          altText: 'BBSN Logo',
          text: 'Text 5',
        },
      ]
         // Populate this array with the URLs of your images
    };
  },
  mounted() {
    // Fetch the image URLs from your directory or import a predefined list
    // and populate the imageUrls array here
  }
};
</script>
<style scoped>
.heading-title {
  align-content: left;
  align-items: left;
  align-self: flex-start;
  text-align: left;
}
.heading-title2 {
  align-content: flex-end;
  align-items: flex-end;
  align-self: flex-end;
  text-align: right;
}

/* CSS Document */

marquee {
	margin-top: 5px;
	width: 100%;
}

.runtext-container {
background-repeat:repeat-x;
width: 100%;
overflow: hidden;
height: 250px;
}

.ticker-image {
  height: 70px;
  width: auto;
}

.main-runtext {
  margin: 0 auto;
  width: 100%;
  overflow: visible;
  position: relative;
  height: 40px;
}

.runtext-container .holder {
position: relative;
overflow: visible;
display:inline;
float:left;

}

.runtext-container .holder .text-container {
	display:inline;
}

.runtext-container .holder a{
	text-decoration: none;
	font-weight: bold;
	color:#ff0000;
	text-shadow:0 -1px 0 rgba(0,0,0,0.25);
	line-height: -0.5em;
	font-size:16px;
}

.runtext-container .holder a:hover{
	text-decoration: none;
	color:#6600ff;
}

</style>