<template>
  <div>
    <!-- Start Portfolio Activation  -->
    <VueSlickCarousel
      v-bind="settings"
      class="rn-slick-activation portfolio-slick-activation2 item-fluid slick-dotted rn-slick-dot mt--40 mt_sm--40"
    >
      <!-- Start Single Portfolio  -->
      <div class="portfolio3" v-for="(item, i) in portfolioContent" :key="i">
        <div class="content">
          <div class="inner">
            <p>{{ item.meta }}</p>
            <h5>
              <router-link to="portfolio-details">{{ item.title }}</router-link>
            </h5>
          </div>
          <div class="portfolio-button pa-0">
            <a class="rn-btn pa-0" :href="item.link" target="_blank">View More ...</a>
          </div>
        </div>
      </div>
      <!-- End Single Portfolio  -->
    </VueSlickCarousel>
  </div>
</template>

<script>
  import VueSlickCarousel from "vue-slick-carousel";
  export default {
    components: {
      VueSlickCarousel,
    },
    data() {
      return {
        portfolioContent: [
          {
            meta: "2021",
            title: "I-Motion integrates data from Vessel Traffic Services (VTS) and Coastal Radio Stations (SROP) as well as various other sources, in order to carry out the monitoring of vessel traffic in Indonesian maritime",
            link: "https://www.antaranews.com/berita/2558921/kemenhub-luncurkan-sistem-integrasi-kenavigasian-i-motion",
          },
          {
            meta: "2019",
            title: "The first to implement Confluent Enterprise at biggest consumer bank in Indonesia",
            link: "https://www.confluent.io/customers/bri/",
          },
          {
            meta: "2020",
            title: "Qlik Announces Recipients of its Global and Regional Partner Awards Awards Recognize Qlik’s Partner Community for Excellence in Achieving Joint Customer Success Through Innovation",
            link: "https://www.qlik.com/blog/qlik-announces-recipients-of-its-global-and-regional-partner-awards",
          },
          {
            meta: "2021",
            title: "I-Motion integrates data from Vessel Traffic Services (VTS) and Coastal Radio Stations (SROP) as well as various other sources, in order to carry out the monitoring of vessel traffic in Indonesian maritime",
            link: "https://www.antaranews.com/berita/2558921/kemenhub-luncurkan-sistem-integrasi-kenavigasian-i-motion",
          },
          {
            meta: "2019",
            title: "The first to implement Confluent Enterprise at biggest consumer bank in Indonesia",
            link: "https://www.confluent.io/customers/bri/",
          },
          {
            meta: "2020",
            title: "Qlik Announces Recipients of its Global and Regional Partner Awards Awards Recognize Qlik’s Partner Community for Excellence in Achieving Joint Customer Success Through Innovation",
            link: "https://www.qlik.com/blog/qlik-announces-recipients-of-its-global-and-regional-partner-awards",
          },
        ],
        // for all works
        settings: {
          dots: true,
          arrows: true,
          infinite: true,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,

          // dots: true,
          // infinite: true,
          // slidesToScroll: 1,
          // autoplay: true,
          // autoplaySpeed: 4000,
          // pauseOnDotsHover: true,
          // pauseOnFocus: true,
          // pauseOnHover: true,


          responsive: [
            {
              breakpoint: 1600,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
              },
            },
            {
              breakpoint: 1263,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
              },
            },
            {
              breakpoint: 770,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1,
              },
            },
            {
              breakpoint: 599,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
              },
            },
          ],
        },
      };
    },
  };
</script>
