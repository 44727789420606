<template>
  <div style="justify-content: center !important; display: flex; flex-direction: column;" class="px-5">
    <!-- <v-container style="height: 500px;">
        <v-row style="justify-content: center !important; ">
          <v-col xl="4" lg="5" md="5" sm="8" cols="8" class="mx-xl-8 mx-lg-8 mx-md-8 mx-sm-auto mx-xs-auto">
            <v-slide-y-reverse-transition>
              <div v-if="showContent">
                <div >
                  <v-img :src="serviceWhite[activeService].logo"
                    style="border-radius: 0%; width: 1000px; margin-top:20px; margin-bottom: 30px;" />
                </div>
              </div>
            </v-slide-y-reverse-transition>
          </v-col>
        </v-row>

        <v-row style="justify-content: center !important;">
          <v-col xl="6" lg="7" md="7" sm="10" cols="12" class="mx-xl-10 mx-lg-10 mx-md-10 mx-sm-auto mx-xs-auto">
            <v-slide-y-reverse-transition>
              <div v-if="showContent">
                <div>
                  <div class="content">
                    <v-card
                      class="mx-auto px-2 text-center"
                      style="width: 800px !important; border-radius: 10px;"
                    >
                      <v-card-text>
                        <h4 class="text--primary" style="font-size: 24px; font-weight: 600;">
                          {{ serviceWhite[activeService].title }}
                        </h4>
                        <div class="text--primary" style="font-size: 18px; font-family: 'Outfit', sans-serif !important; font-weight: 400;">
                          {{ serviceWhite[activeService].description }}
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
              </div>
            </v-slide-y-reverse-transition>
          </v-col>
        </v-row>
    </v-container>

    <v-container class="my-2">
      <v-bottom-navigation shift style="background-color: rgba(0,0,0,0); box-shadow: none;" v-model="activeService">
        <v-btn v-bind:key="index" v-for="(item, index) in serviceWhite">
          <v-img :src="item.logo" style="border-radius: 0%;" />
          <v-icon>mdi-circle-medium</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-container> -->

    <v-row>
      <v-card
        elevation="3"
        class="pa-5 text-left d-flex flex-row"
        style="border-radius: 10px; margin-top: 80px; width: max-content;"
      > 
        <v-row>
          <v-col cols="12" xl="7" lg="7" md="12" sm="12" xs="12">
            <p class="text--primary" style="font-size: 24px !important; font-weight: 600;">
              Your Industry is on the Move
            </p>
            <div class="text--primary" style="font-size: 18px; font-family: 'Outfit', sans-serif !important; font-weight: 400;">
              We understand where it’s going. We align your technology solutions to meet or exceed industry benchmarks, optimize service levels, and generate more operational efficiencies to meet competitive pressures and capture more opportunities.
            </div>
          </v-col>

          <v-col cols="12" xl="5" lg="5" md="12" sm="12" xs="12">
            <div class="text--primary" style="font-size: 18px; font-family: 'Outfit', sans-serif !important; font-weight: 400;">
              <table className="list-desc">
                <tr>
                  <td class="centang"><v-img src="../../assets/images/icons/checkList/check_23.png" /></td>&nbsp;
                  <td>Usecase exploration & development</td>
                </tr>
                <tr>
                  <td class="centang"><v-img src="../../assets/images/icons/checkList/check_23.png" /></td>&nbsp;
                  <td>Architecture design & Review</td>
                </tr>
                <tr>
                  <td class="centang"><v-img src="../../assets/images/icons/checkList/check_23.png" /></td>&nbsp;
                  <td>Installation, integration and monitoring</td>
                </tr>
                <tr>
                  <td class="centang"><v-img src="../../assets/images/icons/checkList/check_23.png" /></td>&nbsp;
                  <td>Maintenance Support</td>
                </tr>
              </table>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </div>
</template>

<script>
// import checkList from "../../assets/images/icons/checkList/check_23.png";
export default {
  components: {
    // checkList,
  },
  data() {
    return {
      showContent: true,
      activeService: 0,
      selectedService: [
        {
          id: "attunity",
          dark: false,
        },
        {
          id: "confluent",
          dark: false,
        },
        {
          id: "imply",
          dark: false,
        },
        {
          id: "elastic",
          dark: false,
        },
        {
          id: "purple cube",
          dark: false,
        },
        {
          id: "imply",
          dark: false,
        },
      ],
      selectedServiceIndex: "",
      serviceWhite: [
        {
          logo: require("../../assets/images/service/principal/qlik.png"),
          title: 'Universal data replication and data ingestion',
          description: 'Qlik Replicate empowers organizations to accelerate data replication, ingestion and streaming across a wide variety of heterogeneous databases, data warehouses, and big data platforms. Used by hundreds of enterprises worldwide, Qlik Replicate moves your data easily, securely and efficiently with minimal operational impact.',
          linkDetail: '/'
        },
        {
          logo: require("../../assets/images/service/principal/confluent.png"),
          title: 'Complete, enterprise grade distribution of Apache Kafka®',
          description: 'Connect, process, and react to your data in real-time with the foundational platform for data in motion. Confluent Platform offers a complete, self-managed platform for continuously streaming data from across your organization to power rich customer experiences and data-driven operations.',
          linkDetail: '/'
        },
        {
          logo: require("../../assets/images/service/principal/imply.svg"),
          title: 'The Database for Modern Analytics Applications',
          description: 'A complete, real-time database for modern analytics applications, built from Apache Druid.  Available as software or as an Imply-assisted offering.',
          linkDetail: '/'
        },
        {
          logo: require("../../assets/images/service/principal/aerospike.png"),
          title: 'The Real-time Data Platform',
          description: 'The multi-model, NoSQL real-time data platform for multi-cloud, large-scale JSON and SQL use cases. The Aerospike database has the lowest latency with predictable performance at any scale, with industry-leading uptime, requiring up to 80% less infrastructure.',
          linkDetail: '/'
        },
        {
          logo: require("../../assets/images/service/principal/purpleCube.png"),
          title: 'A Unified Future for Data Orchestration',
          description: 'A unified data orchestration platform that is on a mission to revolutionize data engineering with the power of Generative AI. This unique approach enables us to automate complex data pipelines, optimize data flows, and generate valuable insights cost-effectively and with efficiency and accuracy.',
          linkDetail: '/'
        },
        {
          logo: require("../../assets/images/service/principal/elastic.png"),
          title: 'Observability and Security built on Elasticsearch',
          description: 'Scale mountains of data and act on insights, fast. Get unified visibility into your infrastructure and apps. Protect against cyber threats. Turn data into decisions with Elastic.',
          linkDetail: '/'
        },
      ],

      serviceDark: [
        {
          logo: require("../../assets/images/service/principal/attunity2.webp"),
          title: 'Universal real-time data replication and data ingestion',
          p1: 'Accelerate data replication, ingestion and streaming across a wide variety of heterogeneous databases, data warehouses, and big data platforms',
          p2: 'Architected for real-time changed data capture and analytics ready data delivery',
          p3: 'n-to-n automation includes target table creation, mappings, schema synchronization, EDW/Data Mart and data lake creation, management and documentation',
          // p4:'',
          color: '#60a729',
          centang: require("../../assets/images/icons/checkList/check_24.png"),
        },
        {
          logo: require("../../assets/images/service/principal/confluent2.webp"),
          title: 'Enterprise Event Streaming Platform',
          p1: 'Complements Apache Kafka with advanced capabilities designed to help accelerate application development and connectivity',
          p2: 'Enable event transformations through stream processing',
          p3: 'Simplify enterprise operations at scale',
          p4: 'Meet stringent architectural requirements',
          color: '#699cf4',
          centang: require("../../assets/images/icons/checkList/check_15.png"),
        },
        {
          logo: require("../../assets/images/service/principal/imply.webp"),
          title: 'A full-stack data platform, enabling real-time data analytics',
          p1: 'Easy data ingestion. Load streaming or btach data from message buses, data lakes, or data warehouses',
          p2: 'Fast and consistent results. Achieve sub-second analytic queries at any concurrency and scale',
          p3: 'Power data applications. Deliver a consistent and interactive experience to users',
          // p4:'',
          color: '#189ae0',
          centang: require("../../assets/images/icons/checkList/check_20.png"),
        },
        {
          logo: require("../../assets/images/service/principal/aerospike2.webp"),
          title: 'Multi-model, globally distributed data operations',
          p1: 'Key-Value data operations are native',
          p2: 'Document database services are new in Aerospike Database 6',
          p3: 'Proven graph implementations',
          p4: 'Proven time series implementations',
          color: '#c4373a',
          centang: require("../../assets/images/icons/checkList/check_25.png"),
        },
        // {
        //   logo: require("../../assets/images/service/principal/tilaka2.webp"),
        //   title: 'Tilaka Digital Signature Solution',
        //   p1: 'Register Authority Function, will be automatically generated every time there is a new user registration process or e-kyc process',
        //   p2: 'Certificate Authority Function, consisting of three services, in which portal as SaaS (software as a service), API and on premise,',
        //   p3: 'Validating Authority Function, using user consent and authorization',
        //   // p4:'',
        //   color: '#0D5FB3',
        //   centang: require("../../assets/images/icons/checkList/check_32.png"),
        // },
      ],
    };
  },
  watch: {
    activeService(e, v) {
      // console.log(e)
      this.showContent = false
      this.$nextTick(() => {
        this.showContent = true
      })
    },
  }
};
</script>
<style scoped>
.centang {
  vertical-align: top;
  min-width: 17px;
  padding-top: 5px;
  margin-bottom: 10px !important;
}
</style>
